import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
//import { HexColorPicker, HexColorInput } from 'react-colorful'
import { CirclePicker } from 'react-color'

import { WrapBox, Box } from '@/views/page/generator/sections/components'
import { storeGeneratorPipeline } from '@/services/store'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export const EditorSkinColor: FC = observer(() => {
  //  console.log("EditorSkinColor()")

  const { mediaQueries } = useLayoutContext()

  const customWidth = mediaQueries.isTablet ? 170 : -1

  return (
    <WrapBox height={15}>
      <Box title={'Skin color'} customWidth={customWidth}>
        <CirclePicker
          width={customWidth}
          colors={[
            storeGeneratorPipeline.avatar.skinColorInitial,
            '#f5d4cd',
            '#eac2ba',
            '#e0b0a6',
            '#d4a093',
            '#c68d82',
            '#b67c70',
            '#a36a5f',
            '#905c4f',
            '#7b4b41',
            '#653d35',
            '#4e2f2a',
          ]}
          color={storeGeneratorPipeline.avatar.skinColor}
          onChangeComplete={(color) => storeGeneratorPipeline.setSkinColor(color.hex)}
        />
      </Box>
    </WrapBox>
  )
})

EditorSkinColor.displayName = 'EditorSkinColor'
