import React, { FC } from 'react'
import { Box, WrapBox, RangeSlider } from '@/views/page/generator/sections/components'
import { observer } from 'mobx-react-lite'
import { storeEditor } from '@/services/store'

export const EditorFace: FC = observer(() => {
  const { face, setFace } = storeEditor

  return (
    <WrapBox height={15}>
      <Box title={'Eyes'}>
        <RangeSlider max={10} min={0} step={1} values={face.eyes} setValue={(eyes) => setFace({ eyes })} />
      </Box>
      <Box title={'Nose'}>
        <RangeSlider max={10} min={0} step={1} values={face.nose} setValue={(nose) => setFace({ nose })} />
      </Box>
      <Box title={'Lips'}>
        <RangeSlider max={10} min={0} step={1} values={face.lips} setValue={(lips) => setFace({ lips })} />
      </Box>
      <Box title={'Jaw'}>
        <RangeSlider max={10} min={0} step={1} values={face.jaw} setValue={(jaw) => setFace({ jaw })} />
      </Box>
    </WrapBox>
  )
})

EditorFace.displayName = 'EditorFace'
