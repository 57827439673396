import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

import { storeEditor, storeGeneratorPipeline } from '@/services/store'
import { Box, WrapBox, Presets, MPresets } from '@/views/page/generator/sections/components'

export const EditorShirt: FC = observer(() => {
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'

  const { outfits, setOutfits, presets, isOutfitReady } = storeEditor
  const {
    preSettings: { gender },
  } = storeGeneratorPipeline

  if (variant === 'not-mobile') {
    return (
      <div style={mediaQueries.isTablet === false ? { height: '20vh' } : { height: '75%', overflowY: 'scroll' }}>
        <WrapBox height={20}>
          <Box title='Complect'>
            <Presets
              presets={presets(gender, 'complect')}
              value={outfits['complect'].name}
              setValue={(outfitName) =>
                outfitName !== outfits['complect'].name && isOutfitReady && setOutfits('complect', gender, outfitName)
              }
            />
          </Box>

          <Box title='Top'>
            <Presets
              presets={presets(gender, 'top')}
              value={outfits['top'].name}
              setValue={(outfitName) =>
                outfitName !== outfits['top'].name && isOutfitReady && setOutfits('top', gender, outfitName)
              }
            />
          </Box>

          <Box title='Bottom'>
            <Presets
              presets={presets(gender, 'bottom')}
              value={outfits['bottom'].name}
              setValue={(outfitName) =>
                outfitName !== outfits['bottom'].name && isOutfitReady && setOutfits('bottom', gender, outfitName)
              }
            />
          </Box>

          <Box title='Shoes'>
            <Presets
              disabled={!storeEditor.isReady()}
              presets={presets(gender, 'shoes')}
              value={outfits['shoes'].name}
              setValue={(outfitName) =>
                outfitName !== outfits['shoes'].name && isOutfitReady && setOutfits('shoes', gender, outfitName)
              }
            />
          </Box>
        </WrapBox>
      </div>
    )
  } 
  return (
    <WrapBox height={20}>
      <Box title='Clothing'>
        <MPresets
          presetsComplect={presets(gender, 'complect')}
          presetsTop={presets(gender, 'top')}
          presetsBottom={presets(gender, 'bottom')}
          presetsShoes={presets(gender, 'shoes')}
          value={[outfits['complect'].name, outfits['top'].name, outfits['bottom'].name, outfits['shoes'].name]}
          setValue={(outfitName, cl) =>
            outfitName !== outfits[cl].name && isOutfitReady && setOutfits(cl, gender, outfitName)
          }
        />
      </Box>
    </WrapBox>
  )
  
})

EditorShirt.displayName = 'EditorShirt'
