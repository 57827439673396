import { useQuery } from 'react-query'
import { ConfigForGetting, Params, METHODS } from './types'
import { getAccessToken } from '../../adapters/req/config'

export {
  getSetting,
  postAuth,
  createPlayerID,
  createQR,
  getQRFile,
  polingQRFile,
  polingUploadFile,
  createAvatar,
  polingCreatingAvatar,
  exportsFile,
  avatarFile,
  haircutFile,
  glassesFile,
  getLocalModel,
  getFilesToAvatar,
} from './config'

export const request = async (pureEndpoint: string, config: ConfigForGetting, data = {}) => {
  let options: RequestInit = {
    method: config.method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: 'Bearer ' + getAccessToken(),
    },
  }

  if ([METHODS.post, METHODS.put].includes(config.method)) {
    const body = new FormData()
    Object.entries(data).forEach(([key, value]) => body.append(key, value))
    options.body = body
  }

  if (config.preFetching) {
    const newOptions = config.preFetching(data)
    options = { ...options, ...newOptions }
  }

  const response = await fetch(pureEndpoint, options)

  if (!response.ok) {
    return Promise.reject(response)
  }
  if (response.status === 204) {
    return null
  }

  if (config.postFetching) {
    return config.postFetching(response, config.initialData)
  }

  return await response.json()
}

export const useRQ = <T>(
  config: ConfigForGetting,
  params?: Params,
): { data: T; error: unknown; isLoading: boolean; isFetched: boolean } => {
  const { isLoading, error, data, isFetched } = useQuery(
    [config.endpoint, params],
    async ({ queryKey }) => await request(queryKey[0], config, params),
    {
      enabled: !config.privateRequest || getAccessToken() !== '',
    },
  )

  return {
    data: data || config.initialData,
    error,
    isLoading,
    isFetched,
  }
}
