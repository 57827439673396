import React from 'react'
import styled from 'astroturf/react'
import { Container } from '@/components/wrapper'
import { Row, Col } from 'react-grid-system'
import { AvatarPreview } from '@/components/ui'
import { TitleSection } from '@/views/components/common'
import img from '@/assets/demo/avatar-gallery.png'
import { storeGeneratorPipeline } from '@/services/store'

import { useRQ, getSetting } from '@/services/adapters/req'
import { Setting } from '../_helper'

export const NoPhoto = () => {
  const { submitPreSettingsForSampleAvatar } = storeGeneratorPipeline

  // console.info('NoPhoto()')

  const {
    data: {
      texts: {
        sections: { no_photo: PAGE },
      },
    },
  } = useRQ<Setting>(getSetting)

  const subtype = storeGeneratorPipeline.preSettings.gender
  // console.info('subtype:', subtype)

  const sampleAvatars = {
    female: [
      {
        gender: 'female',
        preview: 'sample_avatar_1_preview.png',
        avatar_code: 'cf8c976f-0854-4146-98cf-dcaedfa5c568',
        export_code0: 'ad9c1a68-dd13-44ec-8770-d42b30280390',
        export_code: '568417e5-ad1c-4f1d-ab6d-08dab7ed5c18',
        texture_code: null,
        outfit_slot: 'complect',
        outfit_name: 'CHATIN',
      },
      {
        gender: 'female',
        preview: 'sample_avatar_3_preview.png',
        avatar_code: '8dca8a99-5758-49e3-91f5-0619a599464a',
        export_code0: '46cf2921-2ce4-4532-b575-defedf14f7b0',
        export_code: 'ad71a8c7-7f2a-44f1-ad19-d7ea4a0acb2c',
        texture_code: '79f06c6b-543a-400b-93b7-69bbc8aef40a',
        outfit_slot: 'complect',
        outfit_name: 'ARPI',
      },
      {
        gender: 'female',
        preview: 'sample_avatar_5_preview.png',
        avatar_code: '7075c096-a23a-4f4c-a158-0210052565d3',
        export_code0: '93dec633-d0e4-4146-be71-ab25048082c4',
        export_code: 'be6f5abd-cb85-4f6a-8b35-5f13f2364aab',
        texture_code: null,
        outfit_slot: 'complect',
        outfit_name: 'LORI',
      },
      {
        gender: 'female',
        preview: 'sample_avatar_8_preview.png',
        avatar_code: 'e2bb26b0-af54-4080-93be-0009adb95c3d',
        export_code0: '84493eee-fe61-48e5-9d5e-c6b49193766c',
        export_code: 'f548d35c-6c9c-4aeb-bf91-d7b5a5b8c456',
        //texture_code: 'c1e0bca1-d4f7-44ee-90c3-9cdf9270bd53',
        texture_code: 'ab9dcd91-13d5-4e83-bc8c-f2e6c48b1388',
        outfit_slot: 'complect',
        outfit_name: 'CHATIN',
      },
    ],
    male: [
      {
        gender: 'male',
        preview: 'sample_avatar_0_preview.png',
        avatar_code: 'dfb60246-d64a-4e50-a0d3-27919f2032ee',
        export_code0: '6d9867f4-dc48-45fe-96bd-6d3a202a3883',
        export_code: '8d551a8f-fb8b-411c-9f52-75773f8fada7',
        texture_code: 'c7dd8a71-5930-40f6-b348-3b44b1d3796c',
        outfit_slot: 'complect',
        outfit_name: 'KARI',
      },
      {
        gender: 'male',
        preview: 'sample_avatar_2_preview.png',
        avatar_code: '420c694b-15f1-4b8a-bc52-7ac7fe5578c8',
        export_code0: 'decf2db1-5cc6-4e15-9d2e-fc6af98622ec',
        export_code: '46a5ec40-006b-4bab-a0bf-faa2d196bd52',
        texture_code: null,
        outfit_slot: 'complect',
        outfit_name: 'SEVAN',
      },
      {
        gender: 'male',
        preview: 'sample_avatar_4_preview.png',
        avatar_code: '30bafe2d-a770-4343-a828-6faf6f3dde70',
        export_code0: '27044a40-9cd1-4fd6-a0d4-42bbb694a636',
        export_code: 'fb0096bb-b1d9-48e9-9582-b89336750dbe',
        texture_code: null,
        outfit_slot: 'complect',
        outfit_name: 'ARARAT',
      },
      {
        gender: 'male',
        preview: 'sample_avatar_6_preview.png',
        avatar_code: '78f019b3-51a4-4257-8a4d-ea0df2ff9a02',
        export_code0: '6711d8a6-da3a-4136-9a50-d10e2d121c24',
        export_code: '42ae64b8-966c-4703-b9cd-6c554aaad476',
        texture_code: '624400b5-6392-46b9-bedd-2387ed3eadb6',
        outfit_slot: 'complect',
        outfit_name: 'SEVAN',
      },
      {
        gender: 'male',
        preview: 'sample_avatar_7_preview.png',
        avatar_code: 'ac0be9b0-5ab2-41ac-b9c4-6861a9df5a42',
        export_code0: '47a7eddd-ade4-42a1-8868-d725d2e0abe5',
        export_code: 'd895589a-b8fc-4f52-87ed-c4f3a5be88ef',
        texture_code: null,
        outfit_slot: 'complect',
        outfit_name: 'ARARAT',
      },
    ],
  }

  sampleAvatars['without_specify'] = [...sampleAvatars['female'], ...sampleAvatars['male']]
  sampleAvatars['without_specify'].sort((a, b) => (a.preview > b.preview ? 1 : -1))

  //console.log('sampleAvatars:', sampleAvatars)

  return (
    <Container variant='center'>
      <TitleSection title={PAGE.title} descr={PAGE.descr} />
      <Avatars>
        {sampleAvatars[subtype].map((sampleAvatar) => (
          <ColCard key={sampleAvatar.preview} xs={6} sm={4}>
            <Avatar>
              <AvatarPreview
                handleClick={() => submitPreSettingsForSampleAvatar(sampleAvatar)}
                img={`/avatars/${sampleAvatar.preview}`}
                width={120}
                height={80}
              />
            </Avatar>
          </ColCard>
        ))}
      </Avatars>
    </Container>
  )
}

const Avatars = styled(Row)`
  margin-top: 16px;
  max-width: 408px;
  margin-left: -8px !important;
  margin-right: -8px !important;
`

const ColCard = styled(Col)`
  margin-top: 16px;
  padding-left: 8px !important;
  padding-right: 8px !important;
`

const Avatar = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`
