import { makeAutoObservable } from 'mobx'
import { request, getFilesToAvatar } from '@/services/adapters/req'
import { storeEditor } from './editor'
import { storeGeneratorPipeline } from './generatorPipeline'

export interface Model {
  isPending: boolean
  data?: {
    male: { [key: string]: string }
    female: { [key: string]: string }
  }
}
export interface Outfits {
  isPending: boolean
  data: {
    LORI: { [key: string]: string } | undefined
    KARI: { [key: string]: string } | undefined
    CHATIN: { [key: string]: string } | undefined
    ARPI: { [key: string]: string } | undefined
    SEVAN: { [key: string]: string } | undefined
    ARARAT: { [key: string]: string } | undefined
  }
}

class LocalModel {
  model: Model = {
    isPending: false,
    data: {
      male: null,
      female: null,
    },
  }
  outfit: Outfits = {
    isPending: false,
    data: {
      LORI: undefined,
      KARI: undefined,
      CHATIN: undefined,
      ARPI: undefined,
      SEVAN: undefined,
      ARARAR: undefined,
    },
  }
  fileListToAvatar = {
    isPending: false,
    data: null,
  }

  constructor() {
    makeAutoObservable(this)
  }

  getFileListToAvatar = async () => {
    if (this.fileListToAvatar.isPending) {
      return
    }
    if (this.fileListToAvatar.data) {
      this.getLocalFile()
      return
    }
    this.fileListToAvatar.isPending = true
    const data = await request(getFilesToAvatar.endpoint, getFilesToAvatar)
    this.fileListToAvatar.data = data
    this.fileListToAvatar.isPending = false
    this.getLocalFile()
  }

  getLocalFile = () => {
    const gender = storeGeneratorPipeline.preSettings.gender
    storeEditor.setInitialOutfit(Object.keys(this.fileListToAvatar.data[gender].outfit)[0])
    setTimeout(() => {
      this.getDefaultModel()
      this.getDefaultOutfit()
    }, 0)
  }

  getDefaultModel = async () => {
    const gender = storeGeneratorPipeline.preSettings.gender
    const modelFiles = this.fileListToAvatar.data[gender].model

    if (this.model.isPending || this.model.data[gender]) {
      return
    }
    this.model.isPending = true
    storeGeneratorPipeline.preSettings.gender
    try {
      const modelResponse = await Promise.all(
        modelFiles.map(async ({ url, name }) => {
          const blob = await fetch(url).then((r) => r.blob())
          return [name, URL.createObjectURL(blob)]
        }),
      )

      this.model.data[gender] = modelResponse.reduce((acc, [name, url]) => {
        acc[name] = url
        return acc
      }, {})
      // console.info('Success model')
    } catch (error) {
      // console.error('error', error)
    } finally {
      this.model.isPending = false
    }
  }

  getDefaultOutfit = async () => {
    const gender = storeGeneratorPipeline.preSettings.gender
    const outfitFiles = this.fileListToAvatar.data[gender].outfit[storeEditor.outfitKey]
    if (this.outfit.isPending || this.outfit.data[storeEditor.outfitKey]) {
      return
    }
    this.outfit.isPending = true
    try {
      const outfitResponse = await Promise.all(
        outfitFiles.map(async ({ url, name }) => {
          const blob = await fetch(url).then((r) => r.blob())
          return [name, URL.createObjectURL(blob)]
        }),
      )
      this.outfit.data[storeEditor.outfitKey] = outfitResponse.reduce(
        (acc, [name, url]) => {
          acc[name] = url
          return acc
        },
        { outfitName: storeEditor.outfitKey },
      )
      // console.info('Success outfit')
    } catch (error) {
      // console.error('error', error)
    } finally {
      this.outfit.isPending = false
    }
  }
}

const storeLocalModel = new LocalModel()
export { storeLocalModel }
