import React, { FC, PropsWithChildren, useCallback } from 'react'
import { Range } from 'react-range'
import styled from 'astroturf/react'
import { css } from 'astroturf'

export interface RangeSliderProps {
  values: number[]
  min: number
  max: number
  step: number
  setValue: (arg: number[]) => number[]
}

export const RangeSlider: FC<PropsWithChildren<RangeSliderProps>> = ({ values, min, max, step, setValue }) => {
  const upValues = useCallback(() => {
    setValue([values[0] < max ? values[0] + 1 : max])
  }, [values, max, step])

  const downValues = useCallback(() => {
    setValue([values[0] > min ? values[0] - 1 : min])
  }, [values, min, step])

  return (
    <Core>
      <Minus width='1em' height='1em' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg' onClick={downValues}>
        <path stroke='currentColor' strokeWidth={0.5} d='M3 25' />
        <path fill='currentColor' d='M0 3h7v1H0z' />
      </Minus>
      <Range
        step={step}
        min={min}
        max={max}
        values={[values]}
        onChange={setValue}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              width: '100%',
              height: '17px',
            }}
          >
            <RenderTrack />
            <RenderProgress
              css={css`
                width: ${(values[0] / max) * 100}%;
              `}
            />
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} style={props.style}>
            <RenderThumb />
          </div>
        )}
      />
      <Plus width='1em' height='1em' viewBox='0 0 7 7' xmlns='http://www.w3.org/2000/svg' onClick={upValues}>
        <path stroke='currentColor' strokeWidth={0.5} d='M3.25.25h.5v6.5h-.5z' />
        <path fill='currentColor' d='M0 3h7v1H0z' />
      </Plus>
    </Core>
  )
}

const Core = styled.div`
  margin-bottom: 16px;
  display: flex;
`

const Plus = styled.svg`
  margin-left: 10px;
  margin-top: 1px;
  color: var(--color-base);
`

const Minus = styled.svg`
  margin-right: 10px;
  margin-top: 1px;
  color: var(--color-base);
`

const RenderTrack = styled.div`
  height: 1px;
  position: relative;
  width: 100%;
  top: 9px;
  background-color: var(--color-base);
  opacity: 0.5;
`

const RenderThumb = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #fff;
`

const RenderProgress = styled.div`
  position: absolute;
  top: 1px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background-image: var(--color-bg-acc-gradient);
`
