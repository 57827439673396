import { storeGeneratorPipeline } from '@/services/store/generatorPipeline'

export const cameraPositions = (position, isNotTablet) => {
  const subtype = storeGeneratorPipeline.avatar.subtype

  switch (position) {
    case 'BODY':
      return { x: 0, y: 2.0, z: isNotTablet ? 4.5 : 5.0, a: isNotTablet ? 0.9 : 1.1, polar: 1.33 }
    case 'HEAD':
      return { x: 0, y: 1.65, z: 0.9, a: 1.65, polar: Math.PI / 2 }

    case 'EditorSkinColor':
      return {
        x: 0,
        y: subtype === 'female' ? 1.6 : 1.68,
        z: 1.1,
        a: subtype === 'female' ? 1.6 : 1.68,
        polar: Math.PI / 2,
      }
    case 'EditorHairColor':
      return { x: 0, y: 1.7, z: 1.5, a: 1.82, polar: Math.PI / 2 }
    case 'EditorFace':
      return { x: 0, y: 1.7, z: 1.1, a: 1.72, polar: Math.PI / 2 }
    case 'EditorBody':
      return { x: 0, y: 0.0, z: 6.0, a: 1.4, polar: 1.33 }
    case 'EditorHair':
      return { x: 0, y: 1.7, z: 1.1, a: 1.72, polar: Math.PI / 2 }
    case 'EditorGlasses':
      return {
        x: 0,
        y: subtype === 'female' ? 1.63 : 1.71,
        z: 1.1,
        a: subtype === 'female' ? 1.63 : 1.71,
        polar: Math.PI / 2,
      }
    case 'EditorShirt':
      return { x: 0, y: 0.0, z: 6.0, a: 1.4, polar: 1.33 }
  }

  return null
}

//  const HEAD_CAMERA_POSITION = { x: 0, y: 1.65, z: 0.9, a: 1.65, polar: Math.PI / 2 }
//  const BODY_CAMERA_POSITION_MOBILE = { x: 0, y: 2.0, z: 5.0, a: 1.1, polar: 1.33 }
//  const BODY_CAMERA_POSITION = { x: 0, y: 2.0, z: 4.5, a: 0.9, polar: 1.33 }
