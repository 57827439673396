import React, { FC, PropsWithChildren } from 'react'
import styled from 'astroturf/react'

export interface TypographyProps extends PropsWithChildren<unknown> {
  onClick?: () => void
  as?: 'button' | 'a' | 'p'
  disabled?: boolean
  variant?: 'accent' | 'main' | 'surface' | 'background' 
  wSize?: 'full' | 'auto'
  isOnlyIcon?: boolean
  className?: string
  type?: 'button' | 'submit' | 'reset'
}
export const Button: FC<TypographyProps> = ({
  children,
  as = 'button',
  variant = 'accent',
  disabled,
  wSize = 'auto',
  onClick,
  isOnlyIcon = false,
  className,
  type = 'button',
}) => {
  return (
    <Btn
      as={as}
      variant={variant}
      disabled={disabled}
      wSize={wSize}
      onClick={onClick ? onClick : undefined}
      onlyIcon={isOnlyIcon}
      className={className}
      type={type}
    >
      <Text>{children}</Text>
    </Btn>
  )
}

export interface BtnProps {
  as?: 'button' | 'a'
  disabled?: boolean
  onlyIcon?: boolean
  variant?: 'accent' | 'main' | 'surface' | 'background' | 'verfiyBtn'
  wSize: 'full' | 'auto' | null
}

const Btn = styled.button<BtnProps>`
  @import '../../assets/styles/mixins.scss';
  @include anim(border-color, background-color);
  height: var(--size-field-height);
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--size-radius);
  padding-left: var(--size-field-padding);
  padding-right: var(--size-field-padding);
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: var(--color-base);
  position: relative;
  fill: currentColor;
  cursor: pointer;

  &:active {
    user-select: none;
  }

  &.wSize-full {
    width: 100%;
  } 

  &.wSize-auto {
    width: auto;
  }
 


  &.onlyIcon {
    padding-left: 0;
    padding-right: 0;
    width: var(--size-field-height);
  }

  &.verfiyBtn {
   width: 150px;
   margin-left: 10px;
  }

  &.variant-accent {
    overflow: hidden;
    color: #fff;
    &:before {
      @import '../../assets/styles/mixins.scss';
      @include anim(background-size, background-position);
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: 200% 200%;

      background-image: var(--color-bg-acc-gradient);
    }

    &:hover {
      &:before {
        background-position: 100% 0;
      }
    }
  }

  &.variant-surface {
    overflow: hidden;
    background-color: var(--color-bg-filed);
    color: var(--color-base);

    &:hover {
      background-color: var(--color-bg-filed-hover);
    }
  }
`
const Text = styled.span`
  position: relative;
  display: block;
  svg {
    display: block;
  }
`
