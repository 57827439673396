import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { storeEditor, storeGeneratorPipeline } from '@/services/store'
import { Presets, WrapBox, Box } from '@/views/page/generator/sections/components'

export const EditorHair: FC = observer(() => {
  const { haircut, setHaircut, haircutPresets } = storeEditor
  const {
    preSettings: { gender },
  } = storeGeneratorPipeline

  const { mediaQueries } = useLayoutContext()

  //console.log("haircut:", haircut)

  return (
    <div style={mediaQueries.isTablet === false ? { height: '20vh' } : { height: '75%', overflowY: 'scroll' }}>
      <WrapBox height={20}>
        <Box title={'Hair'}>
          <Presets
            disabled={!storeEditor.isReady()}
            presets={haircutPresets(gender)}
            value={haircut.preset || 'HaircutGenerated'}
            setValue={(preset) => setHaircut(gender, preset)}
          />
        </Box>
      </WrapBox>
    </div>
  )
})

EditorHair.displayName = 'EditorHair'
