import React, { FC, PropsWithChildren } from 'react'
import styled from 'astroturf/react'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export interface BoxProps {
  title?: string
  customWidth?: string
}

export const Box: FC<PropsWithChildren<BoxProps>> = ({ children, title, customWidth }) => {
  const { mediaQueries } = useLayoutContext()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'
  const width = variant === 'not-mobile' && customWidth ? customWidth : '220px'

  return (
    <Core variant={variant} width={width}>
      {title && <Title>{title}</Title>}
      {children}
    </Core>
  )
}

export interface CoreProps {
  variant: 'not-mobile' | 'mobile'
  width: string
}

const Core = styled.div<CoreProps>`
  background-color: var(--color-bg-box);
  flex-shrink: 0;

  &.variant-mobile {
    width: 100%;
    padding: 0 32px 20px;

    &:first-child {
      padding-top: 4px;
      &:before {
        content: '';
        margin-bottom: 14px;
        width: 40px;
        height: 2px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--color-base);
        opacity: 0.2;
        border-radius: 4px;
      }
    }
  }

  &.variant-not-mobile {
    width: ${props => props.width};
    border-radius: var(--size-radius-large);
    padding: 20px;
    + div {
      margin-top: 12px;
    }
  }
`

const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: var(--color-base);
  margin-bottom: 12px;
`
