import { makeAutoObservable } from 'mobx'
import {
  request,
  createAvatar,
  polingCreatingAvatar,
  exportsFile,
  avatarFile,
  haircutFile,
  glassesFile,
} from '@/services/adapters/req'
import { exportParameters, avatarParameters } from '@/helpers/exportParameters'
//import { saveAs } from 'file-saver'
import { handleError, toast } from '@/services/adapters/toast'
//import { BlobReader, ZipReader, BlobWriter, Entry } from '@zip.js/zip.js'
import { storeEditor } from './editor'
import { Gender } from '@/helpers/types'
import axios from 'axios'
//import { LayoutContext, LayoutContextResult } from '@/views/components/core/LayoutProvider'
//import { add } from 'ramda'
import { getApiUrl, getAccessToken } from '../adapters/req/config'

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null
}

function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}'
}

export interface PreSettings {
  gender: Gender
  photo?: File
}

const ExportType = {
  Whole: 0,
  Body: 1,
  Outfit: 2,
}

class GeneratorPipeline {
  sectionIndex = 0
  availableSections: string[] = []
  actualNumberSection: { [key: string]: number } = {}
  preSettings: PreSettings = {
    gender: 'female',
  }
  avatarFiles = null
  editorIndex: null | number = null
  avatarId: string | undefined

  startStatePhoto: (() => void) | null = null

  isSendingAvatar = false

  isSendingPreSetting = false
  isPendingGetAvatar = false
  progress = 0

  businessToken = ''
  businessMode = ''
  skipSampleAvatars = false
  avatar = {
    id: 0,
    exportID: 0,
    photo: null,
    subtype: null,
    zip: null,
    skinColor: null,
    skinColorInitial: null,
    isBald: false,
  }

  avatarState = {
    haircut: { name: null, color: null },
    glasses: { name: null },
    outfits: null,
    skinColor: null,
    bodyShape: [],
    faceModifications: [],
  }

  constructor() {
    makeAutoObservable(this)
  }

  setSkinColor = (color: string) => {
    // console.info('setSkinColor(), color:', color)
    this.avatar = { ...this.avatar, skinColor: color }
    this.avatarState.skinColor = color
  }

  setProgress(progress) {
    this.progress = progress
  }

  setAvatarId(avatarId: string) {
    this.avatarId = avatarId
  }

  cleanPipeline = () => {
    this.preSettings = {
      gender: 'male',
    }
    this.avatarFiles = null
    this.setProgress(0)
  }

  setStartStatePhoto = (payload: (() => void) | null) => {
    this.startStatePhoto = payload
  }

  setAvailableSections = (payload: string[]) => {
    this.availableSections = payload
    this.actualNumberSection = payload?.reduce((acc, val, i) => {
      return { ...acc, [val]: i }
    }, {})
  }

  setSectionIndex = (payload: number) => {
    this.sectionIndex = payload
  }

  nextSection = () => {
    const nextNumber: number = this.sectionIndex + 1
    if (nextNumber < this.availableSections.length) {
      this.setSectionIndex(nextNumber)
    }
  }

  toSection = (section: string) => {
    if (this.availableSections.includes(section)) {
      this.setSectionIndex(this.actualNumberSection[section])
    }
  }

  setPreSettings = (key: string, value: string) => {
    this.preSettings[key] = value

    // window.parent.postMessage(
    //   {
    //     setting: JSON.stringify(this.preSettings),
    //     eventName: 'presetAvatar',
    //     source: 'metaperson_creator'
    //   },
    //   '*',
    // )
  }

  submitPreSettingsForSampleAvatar = async (sampleAvatar) => {
    // console.info('submitPreSettingsForSampleAvatar()')
    // console.info('  preview:', sampleAvatar.preview)
    // console.info('  avatar_code:', sampleAvatar.avatar_code)
    // console.info('  export_code:', sampleAvatar.export_code)
    // console.info('  texture_code:', sampleAvatar.texture_code)

    //console.log('  preSettings.gender:', this.preSettings.gender)

    if (this.preSettings.gender === 'without_specify') {
      this.preSettings.gender = sampleAvatar.gender
    }

    this.isSendingPreSetting = true

    this.avatarState = {
      haircut: { name: null, color: null },
      glasses: { name: null },
      outfits: null,
      skinColor: null,
      bodyShape: [],
      faceModifications: [],
    }

    let aiTextureUrl = null

    if (sampleAvatar.texture_code) {
      const headers = {
        Authorization: 'Bearer ' + getAccessToken(),
        'content-type': 'application/x-www-form-urlencoded',
      }

      //sampleAvatar.texture_code = '2567c485-8aa5-448d-b4c4-eaff71cb57b1'

      const endpoint = getApiUrl(`/generated_textures/${sampleAvatar.texture_code}/file/`)

      //console.info('endpoint:', endpoint)
      //console.info('headers:', headers)

      //const res = await axios.get(`${endpoint}`, { headers: headers, responseType: 'blob' })
      //console.info("res:", res)
      //const blob = new Blob([res.data])

      const data = await fetch(endpoint, { method: 'GET', headers: headers })
      //console.log('  data:', data)

      const blob = await data.blob()
      //console.log('  blob:', blob)

      aiTextureUrl = URL.createObjectURL(blob)

      if (process.env.USE_SAVETEXTURE === '1') {
        const link = document.createElement('a')
        link.download = 'aiTexture.webp'
        link.href = aiTextureUrl
        link.click()
        link.delete
      }
    }

    this.avatarState = {
      haircut: { name: null, color: null },
      glasses: { name: null },
      outfits: null,
      skinColor: null,
      bodyShape: [],
      faceModifications: [],
    }

    storeEditor.setInitialOutfits(this.preSettings.gender)
    storeEditor.setInitialHaircut(this.preSettings.gender)
    storeEditor.setInitialGlasses(this.preSettings.gender)

    this.setAvatarId(sampleAvatar.avatar_code)
    this.getAvatarFile(sampleAvatar.avatar_code, [{ code: sampleAvatar.export_code }])
    storeEditor.setOutfits(
      sampleAvatar.outfit_slot,
      this.preSettings.gender,
      sampleAvatar.outfit_name,
      aiTextureUrl,
      sampleAvatar.texture_code,
    )
  }

  submitPreSettings = async () => {
    this.isSendingPreSetting = true
    this.avatarState = {
      haircut: { name: null, color: null },
      glasses: { name: null },
      outfits: null,
      skinColor: null,
      bodyShape: [],
      faceModifications: [],
    }

    if (process.env.USE_PREPAREDAVATAR === '1') {
      storeEditor.setInitialOutfits(this.preSettings.gender)
      storeEditor.setInitialHaircut(this.preSettings.gender)
      storeEditor.setInitialGlasses(this.preSettings.gender)
      this.getAvatarFile(new Date().getTime(), null)
    } else {
      try {
        if (this.preSettings.gender === 'without_specify') {
          // console.info('gender "without_specify" changed to "female"')
          this.preSettings.gender = 'female'
        }

        const body = {
          name: 'test creator',
          pipeline: 'metaperson_2.0',
          pipeline_subtype: this.preSettings.gender,
          parameters: JSON.stringify(avatarParameters()),
          export_parameters: JSON.stringify(exportParameters(this.preSettings.gender)),
          photo: this.preSettings.photo,
        }

        // console.info('body:', body)

        this.setProgress(0)

        const data = await request(createAvatar.endpoint, createAvatar, body)

        // console.info('data:', data)

        storeEditor.setInitialOutfits(this.preSettings.gender)
        storeEditor.setInitialHaircut(this.preSettings.gender)
        storeEditor.setInitialGlasses(this.preSettings.gender)

        this.polingAvatar(data.code)
        this.setAvatarId(data.code)
      } catch (error) {
        this.isSendingPreSetting = false
        handleError(error)
      }
    } // process.env.USE_PREPAREDAVATAR
  }

  polingAvatar = async (avatarID: string) => {
    setTimeout(async () => {
      try {
        const data = await request(polingCreatingAvatar.endpoint({ avatarID }), polingCreatingAvatar)
        switch (data.status) {
          case 'Failed':
            this.isSendingPreSetting = false
            toast('Face detection error', 'Please fix error')
            break
          case 'Completed':
            this.getExportsFile(avatarID)
            this.setProgress(data.progress - 1)
            break
          case 'Queued':
            this.polingAvatar(avatarID)
            break
          default:
            this.polingAvatar(avatarID)
            this.setProgress(data.progress)
            break
        }
      } catch (error) {
        this.isSendingPreSetting = false
        handleError(error)
      }
    }, 2000)
  }

  exportComplete = (data: any, isNotBusiness: boolean, exportType = ExportType.Whole) => {
    const link = isNotBusiness ? `${data?.files[0].file}/?access_token=${getAccessToken()}` : data?.public_file
    if (!isNotBusiness) {
      const eventType = exportType !== ExportType.Outfit ? 'model_exported' : 'outfit_exported'
      window.parent.postMessage(
        {
          url: link,
          eventName: eventType,
          source: 'metaperson_creator',
        },
        '*',
      )
    } else {
      window.location.href = link
    }
  }

  exportingPollingReq = async (
    exportData: JSON | undefined,
    avatarId: string | undefined,
    exportCode: string | undefined,
    playerID: string,
    setIsOpenModal: any,
    exportType = ExportType.Whole,
  ) => {
    const res = await axios
      .get(getApiUrl(`avatars/${avatarId}/exports/${exportCode}/`), {
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          'X-PlayerUID': playerID,
        },
      })
      .then((response) => {
        switch (response?.data?.status) {
          case 'Failed':
            this.isSendingPreSetting = false
            toast('Export error', 'Please try one more time')
            break
          case 'Completed':
            if (exportType !== ExportType.Body) {
              this.setProgress(100)
            }
            this.exportComplete(response.data, isEmptyObject(exportData), exportType)
            if (exportType !== ExportType.Body) {
              setIsOpenModal(false)
            } else {
              this.sendExportRequest(exportData, avatarId, setIsOpenModal, ExportType.Outfit)
            }
            break
          default:
            this.exportingPollingReq(exportData, avatarId, response?.data?.code, playerID, setIsOpenModal, exportType)
            this.progress += exportType !== ExportType.Whole ? 2.5 : 5
            break
        }
      })
  }

  getBusinessToken = async (clientId: string, clientSecret: string) => {
    try {
      const tokenRes = await axios.post(
        getApiUrl('o/token/'),
        {
          grant_type: 'client_credentials',
          client_id: clientId,
          client_secret: clientSecret,
        },
        { headers: { 'content-type': 'application/x-www-form-urlencoded' } },
      )

      this.businessToken = tokenRes.data?.access_token

      const exportTemplatesRes = await axios.get(getApiUrl('export_parameters/templates/'), {
        headers: { Authorization: 'Bearer ' + this.businessToken },
      })

      this.businessMode = 'active'
    } catch (error) {
      this.businessToken = ''
      this.businessMode = 'error'
    }
  }

  addHaircutColor = (js: Record<string, any>) => {
    const hex = this.avatarState.haircut.color
    if (hex !== null) {
      const rgb = hexToRgb(hex)
      js.haircuts.colors = {
        red: rgb.r,
        green: rgb.g,
        blue: rgb.b,
      }
    }
  }

  addSkinColor = (js: Record<string, any>) => {
    const hex = this.avatarState.skinColor
    if (hex !== null) {
      const rgb = hexToRgb(hex)
      js.avatar.colors = {
        red: rgb.r,
        green: rgb.g,
        blue: rgb.b,
      }
    }
  }

  addOutfits = (js: JSON) => {
    const addOutfit = (outfitType, outfitName, texture_code = null) => {
      js[outfitType] = {
        list: [outfitName],
        embed: true,
        textures: {
          embed: true,
          list: ['Color', 'Normal', 'Roughness', 'Metallic'],
        },
      }

      if (texture_code) {
        js[outfitType]['textures']['generated'] = {
          [outfitName]: texture_code,
        }
      }
    }

    if (this.avatarState.outfits.complect) {
      addOutfit('outfits', this.avatarState.outfits.complect.name, this.avatarState.outfits.complect.texture_code)
    }
    if (this.avatarState.outfits.top) {
      addOutfit('outfits_top', this.avatarState.outfits.top.name, this.avatarState.outfits.top.texture_code)
    }
    if (this.avatarState.outfits.bottom) {
      addOutfit('outfits_bottom', this.avatarState.outfits.bottom.name, this.avatarState.outfits.bottom.texture_code)
    }
    if (this.avatarState.outfits.shoes) {
      addOutfit('outfits_shoes', this.avatarState.outfits.shoes.name, this.avatarState.outfits.shoes.texture_code)
    }

    // console.info('js:', js)
  }

  addBodyBlendshapes = (js: JSON) => {
    const bodyBlends = this.avatarState.bodyShape
    if (!js['blendshapes']['values']) {
      js['blendshapes']['values'] = { body_shape: {}, face_modifications: {} }
    }
    for (const key in bodyBlends) {
      js['blendshapes']['values']['body_shape'][key] = bodyBlends[key]
    }
  }

  addFaceBlendshapes = (js: JSON) => {
    const faceBlends = this.avatarState.faceModifications
    if (!js['blendshapes']['values']) {
      js['blendshapes']['values'] = { body_shape: {}, face_modifications: {} }
    }
    for (const key in faceBlends) {
      js['blendshapes']['values']['face_modifications'][key] = faceBlends[key]
    }
  }

  generateExportJSON = (exportData: JSON, onlyOutfit = false) => {
    const fileFormat = exportData?.format ? exportData?.format : 'fbx'
    const lodNumber = exportData?.lod ? exportData?.lod : 1
    const lod = lodNumber === 1 ? 'LOD1' : 'LOD2'
    const profile = exportData?.textureProfile ? exportData?.textureProfile : '1K.jpg'
    const separateOutfit = exportData?.separateOutfit ? exportData?.separateOutfit : false

    const exportJSON = {
      format: fileFormat,
      lod: lod,
      finalize: true,
      make_public: !isEmptyObject(exportData),
      avatar: {
        list: [
          'AvatarBody',
          'AvatarHead',
          'AvatarEyelashes',
          'AvatarLeftCornea',
          'AvatarRightCornea',
          'AvatarLeftEyeball',
          'AvatarRightEyeball',
          'AvatarTeethLower',
          'AvatarTeethUpper',
        ],
        textures: {
          list: ['Color', 'Normal', 'Roughness'],
        },
      },
      textures: {
        embed: true,
        profile: profile,
      },
      haircuts: {
        list: [this.avatarState.haircut.name],
        embed: true,
        textures: {
          embed: true,
          list: ['AO', 'Color', 'Metallic', 'Normal', 'Roughness', 'Scalp'],
        },
      },
      blendshapes: {
        list: ['mobile_51', 'visemes_14'],
        embed: true,
      },
    }

    if (this.avatarState.glasses.name) {
      exportJSON.glasses = {
        list: [this.avatarState.glasses.name],
        embed: true,
        textures: {
          embed: true,
          list: ['Color', 'GltfMetallicRoughness', 'Metallic', 'Roughness'],
        },
      }
    }

    //    console.log("exportJSON:", exportJSON)

    const exportJSONOutfit = {
      format: fileFormat,
      lod: lod,
      finalize: true,
      make_public: !isEmptyObject(exportData),
      textures: {
        embed: true,
        profile: profile,
      },
      blendshapes: {
        embed: true,
      },
    }

    if (!onlyOutfit) {
      this.addHaircutColor(exportJSON)
      this.addSkinColor(exportJSON)

      if (!separateOutfit) {
        this.addOutfits(exportJSON)
      }
      this.addBodyBlendshapes(exportJSON)
      this.addFaceBlendshapes(exportJSON)
      return exportJSON
    }
    this.addOutfits(exportJSONOutfit)
    this.addBodyBlendshapes(exportJSONOutfit)
    return exportJSONOutfit
  }

  private sendExportRequest(
    exportData: JSON | undefined,
    avatarID: string | undefined,
    setIsOpenModal: any,
    exportType = ExportType.Whole,
  ) {
    setTimeout(async () => {
      try {
        let exportJSON = this.generateExportJSON(exportData)
        if (exportType === ExportType.Outfit) {
          exportJSON = this.generateExportJSON(exportData, true)
        }

        const playerID = await axios.post(
          getApiUrl('players/'),
          {},
          { headers: { Authorization: 'Bearer ' + getAccessToken() } },
        )
        const res = await axios.post(
          getApiUrl(`avatars/${avatarID}/exports/`),
          {
            parameters: JSON.stringify(exportJSON),
          },
          {
            headers: {
              Authorization: 'Bearer ' + getAccessToken(),
              'X-PlayerUID': playerID?.data?.code,
            },
          },
        )

        switch (res.data?.status) {
          case 'Failed':
            this.isSendingPreSetting = false
            toast('Error in export', 'Please try one more time')
            setIsOpenModal(false)
            break
          case 'Completed':
            if (exportType !== ExportType.Body) {
              this.setProgress(100)
            }
            this.exportComplete(res.data, isEmptyObject(exportData), exportType)
            if (exportType !== ExportType.Body) {
              setIsOpenModal(false)
            } else {
              this.sendExportRequest(exportData, avatarID, setIsOpenModal, ExportType.Outfit)
            }
            break
          default:
            await this.exportingPollingReq(
              exportData,
              avatarID,
              res?.data?.code,
              playerID?.data?.code,
              setIsOpenModal,
              exportType,
            )
            this.progress += exportType !== ExportType.Whole ? 2.5 : 5
            break
        }
      } catch (error) {
        this.isSendingPreSetting = false
        handleError(error)
      }
    }, 1000)
  }

  exportingAvatar = async (avatarId: string | undefined, setIsOpenModal: any, exportData: JSON | undefined) => {
    // console.info('exportingAvatar()')
    // console.info('  avatarId:', avatarId)
    // console.info('  exportData:', exportData)

    this.setProgress(0)
    this.isSendingAvatar = true
    const avatarID = avatarId ? avatarId : this.avatarId
    const separateOutfit = exportData?.separateOutfit ? exportData?.separateOutfit : false

    if (!separateOutfit) {
      this.sendExportRequest(exportData, avatarID, setIsOpenModal, ExportType.Whole)
    } else {
      this.sendExportRequest(exportData, avatarID, setIsOpenModal, ExportType.Body)
    }
  }

  getExportsFile = async (avatarID: string) => {
    try {
      const data = await request(exportsFile.endpoint({ avatarID }), exportsFile)
      // console.log("datadata", data);
      this.getAvatarFile(avatarID, data)
      return data
    } catch (error) {
      this.isSendingPreSetting = false
      handleError(error)
    }
    return null
  }

  getAvatarFile = async (avatarID: string, exportsFile: [{ code: string }]) => {
    if (this.isPendingGetAvatar) {
      return
    }

    this.isPendingGetAvatar = true

    try {
      const femalePath = process.env.USE_LOD2 === '0' ? '/avatars/female.zip' : '/avatars/female_lod2.zip'
      const malePath = process.env.USE_LOD2 === '0' ? '/avatars/male.zip' : '/avatars/male_lod2.zip'

      const zipList =
        process.env.USE_PREPAREDAVATAR === '1'
          ? await fetch(this.preSettings.gender === 'male' ? malePath : femalePath).then((r) => r.blob())
          : await request(avatarFile.endpoint({ avatarID, exportsCodeID: exportsFile[0].code }), avatarFile)

      //saveAs(zipList, "avatar.zip")
      this.setProgress(100)

      if (this.businessMode === 'active' && process.env.USE_PREPAREDAVATAR !== '1') {
        const baseData = btoa('{"id":' + Math.floor(Math.random() * 100) + ',"code":"' + avatarID + '"}')

        //console.log(baseData)
        const reportRes = await axios.post(
          getApiUrl('report/?p=3'),
          { data: baseData },
          {
            headers: {
              Authorization: 'Bearer ' + this.businessToken,
              'content-type': 'application/x-www-form-urlencoded',
            },
          },
        )
      }

      this.avatar = {
        id: avatarID,
        exportsID: exportsFile ? exportsFile[0].code : 0,
        photo: this.preSettings.photo,
        subtype: this.preSettings.gender,
        zip: zipList,
        skinColorInitial: null,
        isBald: false,
        skinColor: null,
      }

      storeEditor.cleanViewerState()

      /*
this.createAIGeneratedOutfitTexture('ARPI', 'cats', (res) => {
  if (res.status !== 'Completed') {
    console.info('status:', res.status)
    return
  }

  console.log('!!!!! setOutfit ai texture, res.data:', res.data)
  storeEditor.setOutfits('complect', 'female', 'ARPI', res.data)
})
*/

      if (this.availableSections.includes('choice')) {
        this.toSection('choice')
      } else {
        this.toSection('editor')
      }
      this.isSendingPreSetting = false
    } catch (error) {
      this.isSendingPreSetting = false
      handleError(error)
    } finally {
      this.isPendingGetAvatar = false
    }
  }

  getHaircut = async (name: string) => {
    //console.info('getHaircut(), name:', name)

    const zip =
      process.env.USE_PREPAREDAVATAR === '1'
        ? await fetch('/haircuts/' + this.avatar.subtype + '/' + name + '.zip').then((r) => r.blob())
        : await request(
          haircutFile.endpoint({ avatarID: this.avatar.id, exportsCodeID: this.avatar.exportsID, haircutName: name }),
          haircutFile,
        )

    return zip
  }

  getGlasses = async (name: string) => {
    // console.info('getGlasses(), name:', name)

    const zip =
      process.env.USE_PREPAREDAVATAR === '1'
        ? await fetch('/glasses/' + this.avatar.subtype + '/' + name + '.zip').then((r) => r.blob())
        : await request(
          glassesFile.endpoint({ avatarID: this.avatar.id, exportsCodeID: this.avatar.exportsID, glassesName: name }),
          glassesFile,
        )
    //saveAs(zip, "glasses.zip")

    return zip
  }

  setAndNext = (key: string, value: string) => {
    this.setPreSettings(key, value)
    this.nextSection()
  }

  setAndTo = (key: string, value: string, section: string) => {
    this.setPreSettings(key, value)
    this.toSection(section)
  }

  /**
   * create AI generated outfit texture
   *
   * outfitName     - selected outfit
   * text           - prompt for AI texture generator
   * statusCallback - callback for process status
   */

  // create AI generated outfit texture

  createAIGeneratedOutfitTexture = async (outfitName: string, text: string, statusCallback: any) => {
    // console.info('startCreateAIGeneratedOutfitTexture()')
    // console.info('  outfitName:', outfitName)
    // console.info('  prompt:', text)

    statusCallback({ status: 'Started', data: null })

    const headers = {
      Authorization: 'Bearer ' + getAccessToken(),
      'content-type': 'application/x-www-form-urlencoded',
    }
    //console.log("headers:", headers)

    const data = {
      avatar: this.avatar.id,
      outfit: outfitName,
      prompt: text,
    }
    //console.log("data:", data)

    let res = await axios.post(getApiUrl('generated_textures/'), data, { headers })
    // console.info('res:', res)

    const code = res.data['code']
    // console.info('code:', code)

    while (res.data['status'] !== 'Completed') {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      res = await axios.get(getApiUrl(`/generated_textures/${code}`), { headers })
      //console.info("status:", res.data["status"])
      statusCallback({ status: res.data['status'], data: null })

      if (res.data['status'] === 'Failed') {
        return
      }
    }

    //console.log("start download generated texture")

    //code = '79f06c6b-543a-400b-93b7-69bbc8aef40a'
    //code = '2567c485-8aa5-448d-b4c4-eaff71cb57b1'

    //console.log("endpoint:", getApiUrl(`generated_textures/${code}/file/`) )
    res = await axios.get(getApiUrl(`generated_textures/${code}/file/`), { headers: headers, responseType: 'blob' })
    //    console.log("res:", res)

    const blob = new Blob([res.data])
    //console.log("blob:", blob)

    const url = URL.createObjectURL(blob)
    //console.log("url:", url)

    if (process.env.USE_SAVETEXTURE === '1') {
      const link = document.createElement('a')
      link.download = 'aiTexture.webp'
      link.href = url
      link.click()
      link.delete
    }

    statusCallback({ status: 'Completed', data: url })
  }
}

const storeGeneratorPipeline = new GeneratorPipeline()

export { storeGeneratorPipeline }
