import { makeAutoObservable, autorun } from 'mobx'
import { request, postAuth } from '@/services/adapters/req'

class Auth {
  isLoading = false
  isAuthorization = false
  isError = false

  constructor() {
    makeAutoObservable(this)
  }

  checkToken() {
    const token = null 
    if (token) {
      this.isLoading = false
      this.isAuthorization = true
    }
  }

  send = async (body: { client_id: string; client_secret: string }) => {
    if (this.isAuthorization || this.isLoading) {
      return
    }
    this.isLoading = true
    await request(postAuth.endpoint, postAuth, body)
    this.isLoading = false
    this.isAuthorization = true
  }
}

const storeAuth = new Auth()

autorun(() => storeAuth.checkToken())

export { storeAuth }
