import React, { StrictMode } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Login, Developer, Confirmation, AuthPages, WaitingConfirmation } from '@/views/page/auth'
import { AuthLayout, PrivatePageLayout } from '@/views/components'
import { Gallery } from '@/views/page/gallery'
import { AppsPages, Apps, Article, AvatarSelection } from '@/views/page/apps'
import { Generator } from '@/views/page/generator'
import { LayoutProvider } from './LayoutProvider'


import { QueryClient, QueryClientProvider } from 'react-query'
//import { ReactQueryDevtools } from 'react-query/devtools'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 240000,
    },
  },
})

import { Support } from '@/helpers/Links'
import { VerificationPage } from '@/views/page/auth/Verification'
import { ProfileDetails } from '@/views/page/auth/ProfileDetails'
const clientID = process.env.USE_MIRROR === '0' ? process.env.CLIENT_ID as string : process.env.CLIENT_ID_MIRROR as string
const clientSecret = process.env.USE_MIRROR === '0' ? process.env.CLIENT_SECRET as string : process.env.    CLIENT_SECRET_MIRROR as string

export const Web = () => {
  return (
    <>
      {/* PERMINOV    <StrictMode> */}
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <LayoutProvider>
            <Support />
            <Routes>
              <Route element={<AuthLayout />}>
                <Route element={<AuthPages />} path='/auth'>
                  <Route path='/auth' element={<Login />} />
                  <Route path='/auth/developer' element={<Developer />} />
                  <Route path='/auth/confirmation' element={<Confirmation />} />
                  <Route path='/auth/waitingConfirmation' element={<WaitingConfirmation />} />
                  <Route path='/auth/profile' element={<ProfileDetails /> } />
                </Route>
              </Route>
              <Route element={<PrivatePageLayout />}>
                <Route path='/gallery' element={<Gallery />} />
                <Route element={<AppsPages />} path='/apps'>
                  <Route path='/apps' element={<Apps />} />
                  <Route path=':article' element={<Article />} />
                  <Route path=':article/avatar-selection' element={<AvatarSelection />} />
                </Route>
                <Route path='/support' element={<Apps />} />
              </Route>
              <Route path='/generator' element={<Generator clientID={clientID} clientSecret={clientSecret} />} />
            </Routes>
          </LayoutProvider>
          {/*<ReactQueryDevtools initialIsOpen />*/}
        </QueryClientProvider>
      </BrowserRouter>
      {/* PERMINOV    </StrictMode> */}
    </>
  )
}
