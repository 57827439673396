import React from 'react'

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { proceedZip } from '../helpers/prepareModel'
import { prepareBodyVisibilityMask } from '../helpers/prepareTextures'

class Outfits {
  refComplect = null
  refTop = null
  refBottom = null
  refShoes = null

  names = { complect: null, top: null, bottom: null, shoes: null }
  nodes = { complect: null, top: null, bottom: null, shoes: null }
  materials = { complect: null, top: null, bottom: null, shoes: null }
  animations = { complect: null, top: null, bottom: null, shoes: null }
  visibilityMasks = { complect: null, top: null, bottom: null, shoes: null, detailed: null, head: null }

  busy = false

  /**
   * Clear model
   */

  clearModel = () => {
    // console.log('clearModel()')

    for (const section of ['complect', 'top', 'bottom', 'shoes']) {
      if (this.names[section]) this.clearModelSection(section)
    }
  }

  /**
   * Clear model section
   */

  clearModelSection = (section) => {
    // console.log('clearModelSection(), section:', section)

    this.names[section] = null

    if (this.nodes[section])
      Object.entries(this.nodes[section]).forEach(([key, value]) => {
        if (value?.geometry) value.geometry.dispose()
      })
    this.nodes[section] = null

    if (this.materials[section])
      Object.entries(this.materials[section]).forEach(([key, value]) => {
        if (value) value.dispose()
      })
    this.materials[section] = null

    this.animations[section] = null

    if (this.visibilityMasks[section]) this.visibilityMasks[section].dispose()
    this.visibilityMasks[section] = null
  }

  /**
   * Prepare Outfits Section
   *
   * param outfits            - object with outfits names
   * param section            - outfits section ('complect', 'top', 'bottom', 'shoes')
   * param onDownloadResource - callback to load .zip and absend in .zip textures
   */

  prepareOutfitsSection = async (outfits, section, onDownloadResource) => {
    // console.log(`prepareOutfitsSection() for ${section}:${outfits[section].name} started`)
    // console.log('  outfits[section]', outfits[section])

    if (outfits[section].name === this.names[section] && outfits[section].texture) {
      // console.log("change outfit's texture")

      const textureLoader = new THREE.TextureLoader()

      const texture = await textureLoader.loadAsync(outfits[section].texture)
      texture.flipY = false
      texture.encoding = THREE.sRGBEncoding

      //console.log("texture:", texture)
      //console.log("this.materials[section][this.names[section]].map:", this.materials[section][this.names[section]].map)

      this.materials[section][this.names[section]].map = texture

      return
    }

    let lodPath = process.env.USE_LOD2 === '0' ? '/' : '/lod2/'
    const zip = await onDownloadResource('outfit', outfits.subtype + lodPath + outfits[section].name + '.zip')

    const blobs = await proceedZip(zip)

    const model = await fetch(blobs['model.gltf'])
      .then((res) => res.text())
      .then((data) => JSON.parse(data))

    if (blobs['model.bin']) model.buffers[0].uri = blobs['model.bin']

    if (blobs['animations.bin']) model.buffers[1].uri = blobs['animations.bin']

    let headVisibilityMaskIndex = -1
    let bodyVisibilityMaskIndex = -1

    for (let i = 0; i < model.images.length; ++i) {
      if (model.images[i].uri.includes('HeadVisibilityMask')) headVisibilityMaskIndex = i
      if (model.images[i].uri.includes('BodyVisibilityMask')) bodyVisibilityMaskIndex = i

      if (model.images[i].uri.includes('Color') && outfits[section].texture) {
        // console.log('Change Color texture')
        model.images[i].uri = outfits[section].texture
      } else {
        model.images[i].uri = blobs[model.images[i].uri]
          ? blobs[model.images[i].uri]
          : URL.createObjectURL(await onDownloadResource('texture', model.images[i].uri))
      }
    }

    const textureLoader = new THREE.TextureLoader()
    const headVisibilityMask =
      headVisibilityMaskIndex > -1 ? await textureLoader.loadAsync(model.images[headVisibilityMaskIndex].uri) : null
    const bodyVisibilityMask =
      bodyVisibilityMaskIndex > -1 ? await textureLoader.loadAsync(model.images[bodyVisibilityMaskIndex].uri) : null

    if (headVisibilityMask) headVisibilityMask.flipY = false
    if (bodyVisibilityMask) bodyVisibilityMask.flipY = false

    const url = URL.createObjectURL(new Blob([JSON.stringify(model, null, 2)], { type: 'text/plain' }))

    var gltfLoader = new GLTFLoader()
    const gltf = await gltfLoader.loadAsync(url)

    const nodes = await gltf.parser.getDependencies('node')
    const materials = await gltf.parser.getDependencies('material')

    if (section === 'complect' || section === 'top') {
      if (this.visibilityMasks['head']) this.visibilityMasks['head'].dispose()
      this.visibilityMasks['head'] = headVisibilityMask
    }

    if (this.visibilityMasks['detailed']) this.visibilityMasks['detailed'].dispose()

    if (section === 'complect') {
      for (const sect of ['complect', 'top', 'bottom', 'shoes']) {
        if (this.names[sect]) this.clearModelSection(sect)
      }

      this.visibilityMasks['complect'] = bodyVisibilityMask
      this.visibilityMasks['detailed'] = null
    } else {
      this.clearModelSection('complect')
      this.clearModelSection(section)

      this.visibilityMasks[section] = bodyVisibilityMask

      this.visibilityMasks['detailed'] = await prepareBodyVisibilityMask(
        this.visibilityMasks['top'],
        this.visibilityMasks['bottom'],
        this.visibilityMasks['shoes'],
      )
    }

    this.names[section] = outfits[section].name

    this.nodes[section] = {}
    nodes.forEach((item) => {
      this.nodes[section][item.name] = item
    })

    this.materials[section] = {}
    materials.forEach((item) => {
      item.side = THREE.DoubleSide
      this.materials[section][item.name] = item
    })

    this.animations[section] = gltf.animations

    if (gltf.animations.length) {
      const mixer = new THREE.AnimationMixer(gltf.scene)
      mixer.clipAction(gltf.animations[0]).play()
      mixer.update(0)
    }

    // console.log(`prepareOutfitsSection() for ${section}:${outfits[section].name} finished`)
  }

  /**
   * Prepare Outfits
   *
   * param outfits            - object with outfits names
   * param onDownloadResource - callback to load .zip and absend in .zip textures
   */

  prepareOutfits = async (outfits, onDownloadResource) => {
    //console.log('prepareOutfits()')
    //console.log('  outfits:', outfits)

    //console.log('this.busy:', this.busy, ', set true')
    this.busy = true

    for (const section of ['complect', 'top', 'bottom', 'shoes']) {
      if (outfits[section].name && outfits[section].name !== this.names[section])
        await this.prepareOutfitsSection(outfits, section, onDownloadResource)
    }

    //console.log('this.busy:', this.busy, ', set false')
    this.busy = false
  }

  /**
   * Update Blendshapes
   *
   * param storeAvatarBody - avatar body data
   */

  updateBlendshapes = (storeAvatarBody) => {
    // console.log('updateBlendshapes()')

    if (storeAvatarBody.nodes?.AvatarBody?.morphTargetDictionary) {
      const bodyMesh = storeAvatarBody.nodes['AvatarBody']

      for (const section of ['complect', 'top', 'bottom', 'shoes']) {
        if (!storeOutfits.nodes[section]) continue

        const outfitMesh = storeOutfits.nodes[section][storeOutfits.names[section]]

        Object.keys(outfitMesh.morphTargetDictionary).forEach((prop) => {
          const outfitProp = outfitMesh.morphTargetDictionary[prop]

          const bodyProp = bodyMesh.morphTargetDictionary[prop]
          if (outfitProp && bodyMesh.morphTargetInfluences[bodyProp])
            outfitMesh.morphTargetInfluences[outfitProp] = bodyMesh.morphTargetInfluences[bodyProp]
        })
      }
    }
  }

  /**
   * Outfits (complect) jsx
   *
   * param ref          - react.js useRef()
   * param params       - outfits' parameters
   */

  jsxOutfitsComplect = (ref, params) => {
    //console.log('jsxOutfitsComplect()')
    //console.log('  this.names:', this.names)

    if (process.env.USE_INVISIBLEOUTFITS === '1') return <group {...params} dispose={null} ref={ref}></group>

    if (!this.nodes['complect']) return null

    const complectName = this.names['complect']

    this.refComplect = ref

    return (
      <group {...params} dispose={null} ref={this.refComplect}>
        <primitive object={this.nodes['complect'].Hips} />
        <skinnedMesh
          name={complectName}
          geometry={this.nodes['complect'][complectName].geometry}
          material={this.materials['complect'][complectName]}
          skeleton={this.nodes['complect'][complectName].skeleton}
          morphTargetDictionary={this.nodes['complect'][complectName].morphTargetDictionary}
          morphTargetInfluences={this.nodes['complect'][complectName].morphTargetInfluences}
        />
      </group>
    )
  }

  /**
   * Outfits (detailed) jsx
   *
   * param refTop    - react.js useref
   * param refBottom - react.js useref
   * param refShoes  - react.js useref
   * param params    - outfits' parameters
   */

  jsxOutfitsDetailed = (refTop, refBottom, refShoes, params) => {
    //console.log('jsxOutfitsDetailed()')
    //console.log('  this.name:', this.names)

    this.refTop = refTop
    this.refBottom = refBottom
    this.refShoes = refShoes

    if (process.env.USE_INVISIBLEOUTFITS === '1')
      return (
        <>
          <group {...params} dispose={null} ref={this.refTop} />
          <group {...params} dispose={null} ref={this.refBottom} />
          <group {...params} dispose={null} ref={this.refShoes} />
        </>
      )

    const topName = this.names['top']
    const bottomName = this.names['bottom']
    const shoesName = this.names['shoes']

    if (!this.nodes['top'] || !this.nodes['bottom'] || !this.nodes['shoes']) return null

    return (
      <>
        <group {...params} dispose={null} ref={this.refTop}>
          <primitive object={this.nodes['top'].Hips} />
          <skinnedMesh
            name={topName}
            geometry={this.nodes['top'][topName].geometry}
            material={this.materials['top'][topName]}
            skeleton={this.nodes['top'][topName].skeleton}
            morphTargetDictionary={this.nodes['top'][topName].morphTargetDictionary}
            morphTargetInfluences={this.nodes['top'][topName].morphTargetInfluences}
          />
        </group>

        <group {...params} dispose={null} ref={this.refBottom}>
          <primitive object={this.nodes['bottom'].Hips} />
          <skinnedMesh
            name={bottomName}
            geometry={this.nodes['bottom'][bottomName].geometry}
            material={this.materials['bottom'][bottomName]}
            skeleton={this.nodes['bottom'][bottomName].skeleton}
            morphTargetDictionary={this.nodes['bottom'][bottomName].morphTargetDictionary}
            morphTargetInfluences={this.nodes['bottom'][bottomName].morphTargetInfluences}
          />
        </group>

        <group {...params} dispose={null} ref={this.refShoes}>
          <primitive object={this.nodes['shoes'].Hips} />
          <skinnedMesh
            name={shoesName}
            geometry={this.nodes['shoes'][shoesName].geometry}
            material={this.materials['shoes'][shoesName]}
            skeleton={this.nodes['shoes'][shoesName].skeleton}
            morphTargetDictionary={this.nodes['shoes'][shoesName].morphTargetDictionary}
            morphTargetInfluences={this.nodes['shoes'][shoesName].morphTargetInfluences}
          />
        </group>
      </>
    )
  }

  /**
   * Change Outfits Blandshapes
   *
   * param bodyParams   - body parameters
   */

  changeBlendshapes = (blendshapes) => {
    //console.log('changeBlendshapes()', blendshapes)

    if (this.refComplect?.current) {
      const mesh = this.refComplect.current.children.find((item) => item.type === 'SkinnedMesh')
      for (const prop in blendshapes) mesh.morphTargetInfluences[mesh.morphTargetDictionary[prop]] = blendshapes[prop]
    }

    if (this.refTop?.current) {
      const mesh = this.refTop.current.children.find((item) => item.type === 'SkinnedMesh')
      for (const prop in blendshapes) mesh.morphTargetInfluences[mesh.morphTargetDictionary[prop]] = blendshapes[prop]
    }

    if (this.refBottom?.current) {
      const mesh = this.refBottom.current.children.find((item) => item.type === 'SkinnedMesh')
      for (const prop in blendshapes) mesh.morphTargetInfluences[mesh.morphTargetDictionary[prop]] = blendshapes[prop]
    }

    if (this.refShoes?.current) {
      const mesh = this.refShoes.current.children.find((item) => item.type === 'SkinnedMesh')
      for (const prop in blendshapes) mesh.morphTargetInfluences[mesh.morphTargetDictionary[prop]] = blendshapes[prop]
    }
  }
}

const storeOutfits = new Outfits()

export { storeOutfits }
