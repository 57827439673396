import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { HexColorPicker, HexColorInput } from 'react-colorful'

import { ColorPicker, WrapBox, Box } from '@/views/page/generator/sections/components'
import { storeEditor } from '@/services/store'

export const EditorHairColor: FC = observer(() => {

  const { haircut, setHaircutColor } = storeEditor

  const customWidth = '190px' // custom width value
  const hexColorPickerStyle = {
    width: "150px",
    height: "150px"
  }
  return (
    <WrapBox>
      <Box title={'Haircut color'} customWidth={customWidth}>
        <HexColorPicker
          color={haircut.color ? haircut.color : '#ffffff'}
          onChange={(color) => setHaircutColor(color)}
          style={hexColorPickerStyle}
        />
      </Box>
    </WrapBox>
  )
})

EditorHairColor.displayName = 'EditorHairColor'
