import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react'
//import { observer } from 'mobx-react-lite'

import * as THREE from 'three'
import { useGLTF } from '@react-three/drei'
import { AnimationMixer } from 'three'
import { useFrame } from '@react-three/fiber'

import { useAnimations, useAnimationsMy } from './helpers/useAnimations'

import { myDelay } from './helpers/myDelay'

import { storeAvatarBody } from './models/AvatarBody'
import { storeHaircut } from './models/Haircut'
import { storeOutfits } from './models/Outfits'
import { storeGlasses } from './models/Glasses'

import { prepareBodyVisibilityMask } from './helpers/prepareTextures'
import { prepareHaircutedHeadTexture } from './helpers/prepareTextures'

/**
 * AvatarViewer React Component
 *
 * param avatar             - avatar object    (see readme.md for details)
 * param haircut            - haircut object   (see readme.md for details)
 * param outfits            - outfits object   (see readme.md for details)
 * param animation          - animation object (see readme.md for details)
 * param params             - params           (see readme.md for details)
 * param onDownloadResource - callback to load absend in .zip textures data (see readme.md for details)
 */

export const AvatarViewerComponent = ({
  avatar,
  haircut,
  outfits,
  glasses,
  animation,
  params,
  onDownloadResource,
  setReady,
}) => {
  // console.log('!!!!!!!!!! <AvatarViewerComponent>')
  //console.log('  avatar:', avatar)
  //console.log('  haircut:', haircut)
  //console.log('  outfits:', outfits)
  //console.log('  glasses:', glasses)
  //console.log('  animation:', animation)
  //console.log('  params:', params)

  const refAvatarBody = useRef(null)
  const refHaircut = useRef(null)
  const refGlasses = useRef(null)

  const refComplect = useRef(null)
  const refTop = useRef(null)
  const refBottom = useRef(null)
  const refShoes = useRef(null)

  const [animStarted, setAnimStarted] = useState(false)

  const { animations: animAnimations } = useGLTF(animation.name)
  const [mixer, setMixer] = React.useState(() => new AnimationMixer(undefined))

  useFrame((state, delta) => {
    mixer.update(delta)
  })

  const startAnimation = () => {
    //console.log('startAnimation()')

    if (refAvatarBody?.current) {
      mixer.clipAction(animAnimations[0], refAvatarBody.current).play()
      mixer.clipAction(animAnimations[0], refAvatarBody.current).paused = false
    }

    if (refHaircut?.current) {
      mixer.clipAction(animAnimations[0], refHaircut.current).paused = true

      mixer.uncacheAction(animAnimations[0], refHaircut.current)

      mixer.clipAction(animAnimations[0], refHaircut.current).time = mixer.clipAction(
        animAnimations[0],
        refAvatarBody.current,
      ).time
      mixer.clipAction(animAnimations[0], refHaircut.current).play()
      mixer.clipAction(animAnimations[0], refHaircut.current).paused = false
    }

    if (refGlasses?.current) {
      mixer.clipAction(animAnimations[0], refGlasses.current).paused = true

      mixer.uncacheAction(animAnimations[0], refGlasses.current)

      mixer.clipAction(animAnimations[0], refGlasses.current).time = mixer.clipAction(
        animAnimations[0],
        refAvatarBody.current,
      ).time
      mixer.clipAction(animAnimations[0], refGlasses.current).play()
      mixer.clipAction(animAnimations[0], refGlasses.current).paused = false
    }

    if (refComplect?.current) {
      mixer.uncacheAction(animAnimations[0], refComplect.current)

      mixer.clipAction(animAnimations[0], refComplect.current).time = mixer.clipAction(
        animAnimations[0],
        refAvatarBody.current,
      ).time
      mixer.clipAction(animAnimations[0], refComplect.current).play()
      mixer.clipAction(animAnimations[0], refComplect.current).paused = false
    }

    if (refTop?.current) {
      mixer.uncacheAction(animAnimations[0], refTop.current)

      mixer.clipAction(animAnimations[0], refTop.current).time = mixer.clipAction(
        animAnimations[0],
        refAvatarBody.current,
      ).time
      mixer.clipAction(animAnimations[0], refTop.current).play()
      mixer.clipAction(animAnimations[0], refTop.current).paused = false
    }

    if (refBottom?.current) {
      mixer.uncacheAction(animAnimations[0], refBottom.current)

      mixer.clipAction(animAnimations[0], refBottom.current).time = mixer.clipAction(
        animAnimations[0],
        refAvatarBody.current,
      ).time
      mixer.clipAction(animAnimations[0], refBottom.current).play()
      mixer.clipAction(animAnimations[0], refBottom.current).paused = false
    }

    if (refShoes?.current) {
      mixer.uncacheAction(animAnimations[0], refShoes.current)

      mixer.clipAction(animAnimations[0], refShoes.current).time = mixer.clipAction(
        animAnimations[0],
        refAvatarBody.current,
      ).time
      mixer.clipAction(animAnimations[0], refShoes.current).play()
      mixer.clipAction(animAnimations[0], refShoes.current).paused = false
    }
  }

  const stopAnimation = () => {
    //console.log('stopAnimation()')

    for (const ref of [refAvatarBody, refHaircut, refGlasses, refComplect, refTop, refBottom, refShoes]) {
      if (ref?.current) mixer.clipAction(animAnimations[0], ref.current).stop()
    }

    storeAvatarBody.applyOutfitsAnimations(storeOutfits)
  }

  /**
   * update AvatarBody
   */

  const updateAvatarBody = async () => {
    //console.log('updateAvatarBody()')
    //console.log('  avatar.id:', avatar.id)
    //console.log('  storeAvatarBody.id:', storeAvatarBody.id)

    setReady('avatar', false)
    setReady('haircut', false)
    setReady('glasses', false)

    storeHaircut.clearModel()
    storeGlasses.clearModel()
    storeOutfits.clearModel()

    await storeAvatarBody.prepareAvatarBody(avatar, onDownloadResource, haircut?.color)
    storeAvatarBody.applyOutfitsAnimations(storeOutfits)

    const skinColorInitial =
      '#' +
      storeAvatarBody.info.skin_color.red.toString(16) + // r
      storeAvatarBody.info.skin_color.green.toString(16) + // g
      storeAvatarBody.info.skin_color.blue.toString(16) // b

    setReady('avatar', true, { skinColorInitial: skinColorInitial, isBald: avatar.isBald })
    setReady('haircut', true)
    setReady('glasses', true)
  }

  /**
   * animStarted changed
   */

  // animStarted changed

  useEffect(() => {
    //console.log('useEffect(), animStarted changed')

    animStarted ? startAnimation() : stopAnimation()
  }, [animStarted])

  /**
   * update haircut
   */

  const updateHaircut = async () => {
    // console.log('updateHaircut()')
    // console.log('  haircut.preset:', haircut.preset)
    // console.log('  storeHaircut.name:', storeHaircut.name)

    setReady('haircut', false)

    await storeHaircut.prepareHaircut(haircut, onDownloadResource)
    recoloringPresetHaircut()

    if (haircut.preset === 'Bald') {
      storeAvatarBody.updateScalpTexture(null)
      storeAvatarBody.setHaircutedHeadTexture(false)
    }

    setReady('haircut', true)

    //storeHaircut.updateTimestamp()
  }

  useLayoutEffect(() => {
    // console.log('useLayoutEffect(), storeHaircut.nodes')
    //console.log('  refHaircut:', refHaircut)

    //console.log("storeHaircut:", storeHaircut)
    storeAvatarBody.updateScalpTexture(haircut.preset !== 'Bald' ? storeHaircut.textures?.Scalp : null)

    if (haircut.preset && animStarted && refHaircut?.current && refAvatarBody?.current) {
      mixer.clipAction(animAnimations[0], refHaircut.current).stop()
      mixer.uncacheAction(animAnimations[0], refHaircut.current)

      const action = mixer.clipAction(animAnimations[0], refHaircut.current)
      action.time = mixer.clipAction(animAnimations[0], refAvatarBody.current).time
      mixer.clipAction(animAnimations[0], refHaircut.current).play()
    }
  }, [storeHaircut.nodes])

  /**
   * update glasses
   */

  const updateGlasses = async () => {
    //console.log('updateGlasses()')
    //console.log('  glasses.name:', glasses.name)
    //console.log('  storeGlasses.name:', storeGlasses.name)

    setReady('glasses', false)

    await storeGlasses.prepareGlasses(glasses, onDownloadResource)

    setReady('glasses', true)
  }

  useLayoutEffect(() => {
    //console.log('useLayoutEffect(), storeGlasses.nodes changed')
    //console.log('  refGlasses:', refGlasses)

    if (animStarted && refGlasses?.current && refAvatarBody?.current) {
      mixer.clipAction(animAnimations[0], refGlasses.current).stop()
      mixer.uncacheAction(animAnimations[0], refGlasses.current)

      const action = mixer.clipAction(animAnimations[0], refGlasses.current)
      action.time = mixer.clipAction(animAnimations[0], refAvatarBody.current).time
      mixer.clipAction(animAnimations[0], refGlasses.current).play()
    }
  }, [storeGlasses.nodes])

  /**
   * recoloring preset haircut
   */

  const recoloringPresetHaircut = async () => {
    // console.log('recoloringPresetHaircut()')
    // console.log('  storeHaircut.name:', storeHaircut.name)
    // console.log('  haircut.color:', haircut.color)

    storeHaircut.recoloringHaircut(
      {
        target: haircut.color,
        roots: haircut.color, // haircut.roots
      },
      {
        AOImpact: 0.75,
        DepthImpact: 1.0,
        IDsImpact: 1.0,
      },
    )

    storeAvatarBody.recoloringScalp(haircut.color) // ? FIX IT - another color for scalp
  }

  /**
   * recoloring generated haircut
   */

  const recoloringGeneratedHaircut = () => {
    // console.log('recoloringGeneratedHaircut()')
    // console.log('  haircut.color:', haircut.color)
    // console.log('  storeHaircut.color:', storeHaircut.color)

    storeAvatarBody.recoloringHaircut(haircut.color)

    storeHaircut.color = haircut.color
    storeHaircut.name = null
  }

  /**
   * Update Outfits
   */

  const updateOutfits = async () => {
    if (!outfits['complect'].name && !outfits['top'].name) return
    // console.log('updateOutfits()')

    //console.log('   performance.memory.usedJSHeapSize, MB:', performance.memory.usedJSHeapSize / 1048576)

    //setReady('outfit', false) // called in caller

    await storeOutfits.prepareOutfits(outfits, onDownloadResource)

    storeOutfits.updateBlendshapes(storeAvatarBody)
    storeAvatarBody.applyOutfitsAnimations(storeOutfits)
    storeAvatarBody.needToApplyVisibilityMasks = true

    setReady('outfit', true)
  }

  /**
   * useEffect(), outfits changed
   */

  useLayoutEffect(() => {
    // console.log('useEffect() for outfits changed, storeOutfits.busy:', storeOutfits.busy)

    if (storeOutfits.busy) return

    if (animStarted && refAvatarBody?.current) {
      for (const ref of [refComplect, refTop, refBottom, refShoes]) {
        if (ref?.current) {
          mixer.clipAction(animAnimations[0], ref.current).stop()
          mixer.uncacheAction(animAnimations[0], ref.current)

          const action = mixer.clipAction(animAnimations[0], ref.current)
          action.time = mixer.clipAction(animAnimations[0], refAvatarBody.current).time
          mixer.clipAction(animAnimations[0], ref.current).play()
        }
      }
    }
  }, [
    storeOutfits.nodes['complect'],
    storeOutfits.nodes['top'],
    storeOutfits.nodes['bottom'],
    storeOutfits.nodes['shoes'],
  ])

  /**
   * onClick event handler
   */

  function onClick(objectName) {
    //console.log('onClick(), objectName:', objectName)

    setAnimStarted((prev) => !prev)
  }

  if (avatar?.zip) {
    if (avatar.zip !== storeAvatarBody.zip) updateAvatarBody()

    if (haircut.preset && !storeHaircut.busy && haircut.preset !== storeHaircut.name) updateHaircut()

    if (glasses.name !== storeGlasses.name && !storeGlasses.busy) updateGlasses()

    //console.log('storeOutfits.names: ', storeOutfits.names)
    if (
      !storeOutfits.busy &&
      (outfits['complect'].name !== storeOutfits.names['complect'] ||
        outfits['top'].name !== storeOutfits.names['top'] ||
        outfits['bottom'].name !== storeOutfits.names['bottom'] ||
        outfits['shoes'].name !== storeOutfits.names['shoes'])
    )
      updateOutfits()

    if (avatar?.skinColor !== storeAvatarBody.skinColor) storeAvatarBody.recoloringSkin(avatar.skinColor)

    if (haircut.preset === null && storeHaircut.name !== null) recoloringGeneratedHaircut()

    if (haircut.color !== storeHaircut.color) haircut.preset ? recoloringPresetHaircut() : recoloringGeneratedHaircut()
  }

  const isBodyPrepared = storeAvatarBody.nodes && storeAvatarBody.materials ? true : false
  const isHaircutPrepared = storeHaircut.nodes && storeHaircut.materials ? true : false
  const isComplectPrepared = storeOutfits.nodes?.complect && storeOutfits.materials?.complect ? true : false
  const isDetailedPrepared =
    storeOutfits.nodes?.top &&
    storeOutfits.materials?.top &&
    storeOutfits.nodes?.bottom &&
    storeOutfits.materials?.bottom &&
    storeOutfits.nodes?.shoes &&
    storeOutfits.materials?.shoes
      ? true
      : false

  if (storeAvatarBody.needToApplyVisibilityMasks) storeAvatarBody.applyVisibilityMasks(storeOutfits.visibilityMasks)

  //myDelay(1000, "before render()")
  // console.log('render')
  //console.log('  storeOutfits.busy:', storeOutfits.busy)
  //console.log('  storeAvatarBody.needToApplyVisibilityMasks:', storeAvatarBody.needToApplyVisibilityMasks)

  return isBodyPrepared && (isComplectPrepared || isDetailedPrepared) ? (
    <>
      {storeAvatarBody.jsxAvatarBody(refAvatarBody, params, onClick)}
      {storeHaircut.jsxHaircut(refHaircut, storeAvatarBody, params)}
      {isComplectPrepared
        ? storeOutfits.jsxOutfitsComplect(refComplect, params)
        : storeOutfits.jsxOutfitsDetailed(refTop, refBottom, refShoes, params)}
      {storeGlasses.jsxGlasses(refGlasses, params)}
    </>
  ) : null
}
