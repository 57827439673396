import { makeAutoObservable, makeObservable, observable, action } from 'mobx'
import { clone } from 'ramda'

import { OutfitKey } from '@/helpers/types'
import { storeGeneratorPipeline, storeLocalModel } from './index'

//import { defaultParams } from '@/avatarViewer'

export interface SkinTone {
  color: string
}
export interface Face {
  eyes: [number]
  nose: [number]
  lips: [number]
  jaw: [number]
}
export interface Body {
  chest: [number]
  forearms: [number]
  waist: [number]
  shoulders: [number]
  hips: [number]
  legs: [number]
}
export interface Viewer {
  changeModel: () => void
  changeOutfit: () => void
  changeFace: () => void
  changeBody: () => void
  changePosition: (arg?: string) => void
}

const defaultFace: Face = {
  eyes: [10],
  nose: [5],
  lips: [5],
  jaw: [10],
}
const defaultBody: Body = {
  chest: [5],
  forearms: [5],
  waist: [5],
  shoulders: [5],
  hips: [5],
  legs: [5],
}
const defaultViewer = {
  changeModel: () => null,
  changeOutfit: () => null,
  changeFace: () => null,
  changeBody: () => null,
  changePosition: () => null,
}

class Editor {
  viewer: Viewer = defaultViewer
  isCamReady = false
  isOutfitReady = false
  isModelReady = false
  isHaircutReady = false
  isGlassesReady = true
  isViewer = false
  face: Face = clone(defaultFace)
  body: Body = clone(defaultBody)

  outfitKey: OutfitKey = 'LORI'

  //  params = defaultParams

  haircut = {
    color: null,
    preset: null,
  }

  haircutNames = {
    // FIX IT - use /public/haircuts/haircuts.json or ask avatarsdk server
    female: [
      'Haircut0',
      'Haircut1',
      'Haircut2',
      'Haircut3',
      'Haircut4',
      'Haircut5',
      'Haircut6',
      'Haircut7',
      'Haircut8',
      'Haircut9',
      'Haircut10',
      'Haircut11',
      'Haircut12',
      'Haircut13',
    ],
    male: [
      'Haircut0',
      'Haircut1',
      'Haircut2',
      'Haircut3',
      'Haircut4',
      'Haircut5',
      'Haircut6',
      'Haircut7',
      'Haircut8',
      'Haircut9',
      'Haircut10',
      'Haircut11',
      'Haircut12',
      'Haircut13',
    ],
  }

  outfits = {
    complect: { name: null },
    top: { name: null },
    bottom: { name: null },
    shoes: { name: null },
  }

  outfitNames = {
    // FIX IT - use /public/outfits/outfits.json or ask avatarsdk server
    female: {
      complect: ['ARPI', 'CHATIN', 'LORI'],
      top: ['hoodie_ARAS', 't-shirt_ARPI'],
      bottom: ['jeans_AYGER', 'jeans_SWAN', 'jeans_VEDI'],
      shoes: ['sneakers_ARPI', 'sneakers_AZAT'],
      initial: {
        complect: 'ARPI',
        top: 'hoodie_ARAS',
        bottom: 'jeans_AYGER',
        shoes: 'sneakers_ARPI',
      },
    },
    male: {
      complect: ['ARARAT', 'KARI', 'SEVAN'],
      top: ['hoodie_AZAT', 'longsleeve_Debed', 'shirt_Olympus', 't-shirt_KARI', 't-shirt_Hakari'],
      bottom: ['jeans_AZAT', 'pants_SEVAN'],
      shoes: ['sneakers_AZAT', 'shoes_KARI', 'sneakers_SEVAN'],
      initial: {
        complect: 'KARI',
        top: 'hoodie_AZAT',
        bottom: 'jeans_AZAT',
        shoes: 'sneakers_AZAT',
      },
    },
  }

  glasses = {
    name: null,
  }

  glassesNames = {
    // FIX IT - use /public/glasses/glasses.json or ask avatarsdk server
    female: [
      'glasses_00',
      'glasses_01',
      'glasses_02',
      'glasses_03',
      'glasses_04',
      'glasses_05',
      'glasses_06',
      'glasses_07',
      'glasses_08',
      'glasses_09',
      'glasses_10',
      'glasses_11',
      'glasses_12',
      'glasses_13',
      'glasses_14',
      'glasses_15',
    ],
    male: [
      'glasses_00',
      'glasses_01',
      'glasses_02',
      'glasses_03',
      'glasses_04',
      'glasses_05',
      'glasses_06',
      'glasses_07',
      'glasses_08',
      'glasses_09',
      'glasses_10',
      'glasses_11',
      'glasses_12',
      'glasses_13',
      'glasses_14',
      'glasses_15',
    ],
  }

  constructor() {
    makeAutoObservable(this)

    /*
    makeObservable(this, {
      isCamReady: observable,
      isModelReady: observable,
      isHaircutReady: observable,
      isOutfitReady: observable,

      setCamReady: observable,
      setModelReady: observable,
      setHaircutReady: observable,
      setOutfitReady: observable,
    })
*/
  }

  setCamReady = (payload: boolean) => {
    //console.info('setCamReady():', payload)
    this.isCamReady = payload
  }

  setOutfitReady = (payload: boolean) => {
    // console.info('setOutfitReady():', payload)
    this.isOutfitReady = payload
  }

  setModelReady = (payload: boolean) => {
    // console.info('setModelReady():', payload)
    this.isModelReady = payload
    //this.isHaircutReady = payload
  }

  setHaircutReady = (payload: boolean) => {
    // console.info('setHaircutReady():', payload)
    this.isHaircutReady = payload
  }

  setGlassesReady = (payload: boolean) => {
    // console.info('setGlassesReady():', payload)
    this.isGlassesReady = payload
  }

  isReady = () => {
    return this.isCamReady && this.isModelReady && this.isHaircutReady && this.isGlassesReady && this.isOutfitReady
  }

  cleanViewerState = () => {
    // console.info('!!!!!!!!!! cleanViewerState')

    this.isCamReady = false
    this.isOutfitReady = false
    this.isModelReady = false
    this.isHaircutReady = false
    this.isGlassesReady = true
    this.viewer = defaultViewer
    this.face = clone(defaultFace)
    this.body = clone(defaultBody)
  }

  setViewer = (payload: Viewer) => {
    if (payload) {
      this.isViewer = true
      this.viewer = payload
    }
  }

  changePosition = (position) => {
    //console.info('changePosition(), position:', position)
    //this.params.camera = { ...this.params.camera, ...position }
    this.viewer.changePosition(position)
  }

  prepareParams = (first: string, second: string, value, isBody) => {
    const res = {
      [first]: value >= 0.5 ? 0 : (0.5 - value) * 2,
      [second]: value >= 0.5 ? (value - 0.5) * 2 : 0,
    }
    if (isBody) {
      if (res[first] !== 0) {
        storeGeneratorPipeline.avatarState.bodyShape[first] = res[first]
      }
      if (res[second] !== 0) {
        storeGeneratorPipeline.avatarState.bodyShape[second] = res[second]
      }
    } else {
      if (res[first] !== 0) {
        storeGeneratorPipeline.avatarState.faceModifications[first] = res[first]
      }
      if (res[second] !== 0) {
        storeGeneratorPipeline.avatarState.faceModifications[second] = res[second]
      }
    }
    return res
  }

  setFace = (payload: Face) => {
    const [data] = Object.entries(payload)
    const facePart = data[0]
    const value = data[1][0] / 10
    const invValue = 1 - value
    let blendshapes = {}

    switch (facePart) {
      case 'eyes': {
        blendshapes = { Eye_SquintH_L: invValue, Eye_SquintH_R: invValue }
        storeGeneratorPipeline.avatarState.faceModifications['Eye_SquintH_L'] = invValue
        storeGeneratorPipeline.avatarState.faceModifications['Eye_SquintH_R'] = invValue
        break
      }
      case 'nose': {
        blendshapes = this.prepareParams('Nose_Sharp', 'Nose_Large', value, false)
        break
      }
      case 'lips': {
        blendshapes = this.prepareParams('Lips_Narrow', 'Lips_Large', value, false)
        break
      }
      case 'jaw': {
        const val = 1 - value
        blendshapes = { Jaw_Narrow: invValue }
        storeGeneratorPipeline.avatarState.faceModifications['Jaw_Narrow'] = invValue
        break
      }
      default:
        break
    }

    this.viewer.changeBlendshapes(blendshapes)

    this.face = { ...this.face, ...payload }
  }

  setBody = (payload: Body) => {
    const [data] = Object.entries(payload)
    const bodyPart = data[0]
    const value = data[1][0] / 10

    let blendshapes = {}

    switch (bodyPart) {
      case 'chest':
        blendshapes = this.prepareParams('Chest-', 'Chest+', value, true)
        break
      case 'forearms':
        blendshapes = this.prepareParams('Forearms-', 'Forearms+', value, true)
        break
      case 'shoulders':
        blendshapes = this.prepareParams('Shoulders-', 'Shoulders+', value, true)
        break
      case 'waist':
        blendshapes = this.prepareParams('Waist-', 'Waist+', value, true)
        break
      case 'hips':
        blendshapes = this.prepareParams('Hips-', 'Hips+', value, true)
        break
      case 'legs':
        blendshapes = this.prepareParams('Legs-', 'Legs+', value, true)
        break
      default:
        break
    }
    this.viewer.changeBlendshapes(blendshapes)

    this.body = { ...this.body, ...payload }
  }

  // for avatar-viewer-2

  setInitialOutfit = (payload: OutfitKey) => {
    this.outfitKey = payload
  }

  setOutfit = (payload: OutfitKey) => {
    this.outfitKey = payload
    this.isOutfitReady = false
    storeLocalModel.getDefaultOutfit()
  }

  changeModel = () => {
    if (this.viewer) {
      // console.info('Avatar changeModel')

      const gender = storeGeneratorPipeline.preSettings.gender
      this.referenceModelURL = storeGeneratorPipeline.avatarFiles?.model
      this.viewer.changeModel({ ...storeLocalModel.model.data[gender], ...storeGeneratorPipeline.avatarFiles })
    }
  }

  changeOutfit = () => {
    if (storeLocalModel.outfit.data[this.outfitKey]) {
      // console.info('Avatar changeOutfit')
      this.viewer.changeOutfit({ ...storeLocalModel.outfit.data[this.outfitKey] })
    }
  }

  // for AvatarViewer

  setHaircutColor = (color: string) => {
    if (this.haircut.preset === 'Bald') {
      return
    }

    storeGeneratorPipeline.avatarState.haircut.color = color
    this.haircut = { ...this.haircut, color: color }
  }

  setHaircut = (subtype: string, name: string) => {
    storeGeneratorPipeline.avatarState.haircut.name = name
    storeGeneratorPipeline.currentHairstyle = name

    this.haircut = {
      color: this.haircut.color,
      subtype: subtype,
      preset: name !== 'HaircutGenerated' ? name : null,
    }
  }

  setInitialHaircut = (subtype: string) => {
    storeGeneratorPipeline.avatarState.haircut.name = 'HaircutGenerated'
    this.haircut = {
      color: null,
      subtype: subtype,
      preset: null,
    }
  }

  setInitialGlasses = (subtype: string) => {
    storeGeneratorPipeline.avatarState.glasses.name = null
    this.glasses.name = null
  }

  setGlasses = (subtype: string, name: string) => {
    storeGeneratorPipeline.avatarState.glasses.name = name !== 'None' ? name : null

    this.glasses = {
      name: name !== 'None' ? name : null,
    }
    //this.isGlassesReady = false
  }

  haircutPresets = (subtype: string) => {
    const res = []

    res.push({ id: 'Bald', img: '/haircuts/' + subtype + '/Bald.png' })

    if (!storeGeneratorPipeline.avatar.isBald) {
      res.push({ id: 'HaircutGenerated', img: '/haircuts/' + subtype + '/HaircutGenerated.png' })
    }

    this.haircutNames[subtype].forEach((val) => {
      res.push({ id: val, img: '/haircuts/' + subtype + '/' + val + '.png' })
    })

    return res
  }

  glassesPresets = (subtype: string) => {
    const res = []
    res.push({ id: 'None', img: '/glasses/' + 'glasses_none.png' })
    this.glassesNames[subtype].forEach((val) => {
      res.push({ id: val, img: '/glasses/' + subtype + '/' + val + '.png' })
    })
    return res
  }

  presets = (subtype: string, section: string) => {
    const res = []
    this.outfitNames[subtype][section].forEach((val) => {
      res.push({ id: val, img: '/outfits/' + subtype + '/' + val + '.png' })
    })
    return res
  }

  setInitialOutfits = (subtype: string) => {
    //console.log("!!!!!!!!!! setInitialOutfits()", subtype)

    this.setOutfits('complect', subtype, this.outfitNames[subtype].initial['complect'])
  }

  setOutfits = (section: string, subtype: string, name: string, texture: any = null, texture_code: string = null) => {
    // console.info(`setOutfits() ${section}:${name}`)

    // console.log("storeLocalModel.outfit.data:", storeLocalModel.outfit.data)

    if (section === 'complect') {
      //console.log('  ...->complect')

      this.outfits = {
        subtype: subtype,
        complect: { name: name, texture: texture, texture_code: texture_code },
        top: { name: null, texture: null, texture_code: null },
        bottom: { name: null, texture: null, texture_code: null },
        shoes: { name: null, texture: null, texture_code: null },
      }
    } else {
      if (this.outfits.top.name === null || this.outfits.bottom.name === null || this.outfits.shoes.name === null) {
        //        console.log('  complect->detailed')

        this.outfits = {
          subtype: subtype,
          complect: { name: null, texture: null, texture_code: null },
          top: {
            name: section === 'top' ? name : subtype === 'male' ? 'hoodie_AZAT' : 'hoodie_ARAS',
            texture: null,
            texture_code: null,
          },
          bottom: {
            name: section === 'bottom' ? name : subtype === 'male' ? 'jeans_AZAT' : 'jeans_AYGER',
            texture: null,
            texture_code: null,
          },
          shoes: {
            name: section === 'shoes' ? name : subtype === 'male' ? 'shoes_KARI' : 'sneakers_ARPI',
            texture: null,
            texture_code: null,
          },
        }
      } else {
        //        console.log('  detailed->detailed')
        this.outfits = {
          ...this.outfits,
          [section]: { name: name, texture: texture, texture_code: texture_code },
        }
      }
    }
    storeGeneratorPipeline.avatarState.outfits = this.outfits
    this.setOutfitReady(false)
  }
}

const storeEditor = new Editor()

export { storeEditor }
