import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'

import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { Presets, WrapBox, Box } from '@/views/page/generator/sections/components'
import { storeEditor, storeGeneratorPipeline } from '@/services/store'

export const EditorGlasses: FC = observer(() => {
  const {
    preSettings: { gender },
  } = storeGeneratorPipeline

  const { glasses, setGlasses, glassesPresets } = storeEditor
  const { mediaQueries } = useLayoutContext()

  return (
    <div style={mediaQueries.isTablet === false ? { height: '20vh' } : { height: '75%', overflowY: 'scroll' }}>
      <WrapBox>
        <Box title='Glasses'>
          <Presets
            disabled={!storeEditor.isReady()}
            presets={glassesPresets(gender)}
            value={glasses.name || 'None'}
            setValue={(preset) => setGlasses(gender, preset)}
          />
        </Box>
      </WrapBox>
    </div>
  )
})

EditorGlasses.displayName = 'EditorGlasses'
