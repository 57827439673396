// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Box-Title-module--cls1--dUfo2 { /*!*/ }\n\n.Box-Title-module--cls2--pcfA9 {\n  font-size: 14px;\n  font-weight: 700;\n  color: var(--color-base);\n  margin-bottom: 12px;\n}", "",{"version":3,"sources":["webpack://./src/views/page/generator/sections/components/Box-Title.module.scss"],"names":[],"mappings":"AAAA,iCAAA,IAAA,EAAA;;AACA;EAGA,eAAA;EACA,gBAAA;EACA,wBAAA;EACA,mBAAA;AACA","sourcesContent":[".cls1 { /*!*/ }\n.cls2 {\ncomposes: cls1;\n\nfont-size: 14px;\nfont-weight: 700;\ncolor: var(--color-base);\nmargin-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cls1": "Box-Title-module--cls1--dUfo2",
	"cls2": "Box-Title-module--cls2--pcfA9 Box-Title-module--cls1--dUfo2"
};
export default ___CSS_LOADER_EXPORT___;
