export const enum METHODS {
  get = 'get',
  post = 'post',
  delete = 'delete',
  put = 'put',
}

export interface ConfigForGetting {
  method: METHODS
  endpoint: string | ((arg: { [key: string]: string | number }) => string)
  initialData: object | string | number | [] | null
  postFetching?: (response: Response, initialData: object | string | number | [] | null) => unknown
  preFetching?: (agr: unknown) => RequestInit
  privateRequest?: boolean
}

export interface Params {
  [key: string]: string | number | boolean | null
}
